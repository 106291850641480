import React, { Fragment, ReactElement, useContext } from 'react'
import ProfileInfo from '../first-layout/components/profile-info'
import ProfileInfoCard from '../first-layout/components/profile-menu-card'
import ProfileMenuList from '../first-layout/components/profile-menu-list'
import { StoryTime } from '../first-layout/components/story-time'

import Lang from '../../assets/img/icons/Lang'
import ChevronDownBlack from '../../assets/img/icons/ChevronDownBlack'
import Message from '../../assets/img/icons/Message'
import Notification from '../../assets/img/icons/Notification'
import CalendarNav from '../../assets/img/icons/CalendarNav'
import { profileMenuData } from '../../data/menuData'
import { IProfileMenuItem } from '../first-layout/components/profile-menu-item'
import { Outlet, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

interface ISecondLayout {
  menuData: IProfileMenuItem[]
  initial: string
  parent: string
  routerData: {
    path: string
    element: ReactElement
  }[]
}

function SecondLayout(props: ISecondLayout) {
  const location = useLocation()
  const { user } = useContext(AuthContext)
  console.log(location?.pathname)
  return (
    <Fragment>
      {/* SIDE-INFO */}
      <div className='sideinfo'>
        <ProfileInfo />
        <div className='profile-menu'>
          {location?.pathname?.split('/')?.[1] === 'student' && (
            <ProfileInfoCard>
              <ProfileMenuList data={profileMenuData} parent={props.parent} />
            </ProfileInfoCard>
          )}

          <ProfileInfoCard>
            <ProfileMenuList data={props.menuData} parent={props.parent} />
          </ProfileInfoCard>
          {location?.pathname?.split('/')?.[1] === 'student' && <StoryTime />}
        </div>
      </div>

      {/* SIDEBAR MOBILE PHOTO*/}
      <div className='sidebar-photo'>
        <img src={require('../../assets/img/mock/student.jpg')} alt='student' />
      </div>

      <div className='support'>
        {/* HEADER */}
        <header className='header'>
          <div className='header__name'>Bosh sahifa</div>

          <nav className='header-nav'>
            {/* Lang */}
            <div className='header-lang'>
              <div className='header-lang__btn'>
                <div className='header-lang__btn-wrap'>
                  <Lang />
                  <div>O’zbek</div>
                  <ChevronDownBlack />
                </div>
              </div>
              <div className='header-lang__list'>
                <a href='#'>Русский</a>
                <a href='#' className='current'>
                  O’zbek
                </a>
              </div>
            </div>
            {/* Lang end */}

            {/* date,notification,message */}
            <div className='header-nav__menu'>
              <a href={`/${user?.role}/${props.parent}/date`} className='header-nav__date'>
                <CalendarNav />
                <span>22.10.2023</span>
              </a>
              <a href={`/${user?.role}/${props.parent}/messages`}>
                <Message />
              </a>
              <a href={`/${user?.role}/${props.parent}/notifications`} className='nots'>
                <Notification />
              </a>
            </div>
            {/* END END END date , notification , message */}
          </nav>
        </header>

        <Outlet />
      </div>
    </Fragment>
  )
}

export default SecondLayout

// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Next Import

// ** Axios

// ** Config

// ** Types
import { AuthValuesType, LoginParams, ErrCallbackType, UserDataType } from './types'
import { API_URL, request } from '../configs/request'
import auth from '../configs/auth'
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom'
import { QueryCache } from '@tanstack/react-query'

const queryCache = new QueryCache({
  onError: error => {
    console.log(error)
  },
  onSuccess: data => {
    console.log(data)
  },
  onSettled: (data, error) => {
    console.log(data, error)
  }
})

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
}

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)

  // ** Hooks
  const navigate = useNavigate()
  const match = useMatch('/sign-in')
  let [searchParams] = useSearchParams()

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const userData = await window.localStorage.getItem('userData')

      console.log(userData, 'userData')

      if (!!userData) {
        setLoading(false)
        setUser(JSON.parse(userData))
      } else {
        localStorage.removeItem('userData')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('accessToken')
        setUser(null)
        setLoading(false)
        if (!match) {
          navigate('/sign-in')
        }
      }
      setLoading(false)
    }

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = (params: LoginParams, errorCallback?: ErrCallbackType) => {
    // axios
    //   .post(authConfig.loginEndpoint, params)
    request({
      url: API_URL + auth.loginEndpoint,
      method: 'post',
      data: params
    })
      .then(async response => {
        localStorage.setItem(auth.storageTokenKeyName, response.data.access_token)
        const returnUrl = searchParams.get('returnUrl')
        setUser({ ...response.data.user })
        window.localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
        window.localStorage.setItem('userData', JSON.stringify(response.data.user))
        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
        navigate(redirectURL as string)
      })

      .catch(err => {
        if (errorCallback) errorCallback(err)
      })
  }

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(auth.storageTokenKeyName)
    navigate('/sign-in')
    queryCache.clear()
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }

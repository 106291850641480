import { Dispatch, ReactNode, SetStateAction, createContext, useState } from 'react'

type RandomContextType = {
  random: number
  setRandom: Dispatch<SetStateAction<number>>
}

export const RandomContext = createContext<RandomContextType | null>({ random: 1, setRandom: () => {} })

export const RandomProvider = ({ children }: { children: ReactNode }) => {
  const [random, setRandom] = useState(Math.random())

  return <RandomContext.Provider value={{ random, setRandom }}>{children}</RandomContext.Provider>
}

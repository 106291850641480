// @ts-nocheck

import React, { useState } from 'react'
import { Calendar as BigCalendar, momentLocalizer, CalendarProps } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Tooltip } from 'react-tooltip'
import ReactDOMServer from 'react-dom/server'
import EachLesson from '../eachLesson'
import MoreLesson from '../moreLesson'
const localizer = momentLocalizer(moment)

const MyEventWrapper: React.FC<any> = ({ children }) => <a data-tooltip-id='my-tooltip'>{children}</a>
const dateCellWrapper: React.FC<any> = dateCellWrapperProps => {
  const style = {
    display: 'flex',
    flex: 1
  }
  return (
    <a
      style={style}
      onClick={() => console.log(dateCellWrapperProps.value)}
      data-tooltip-id='date-cell-wrapper'
      data-some-relevant-attr={moment(dateCellWrapperProps.value).format('DD/MM/YYYY')}
    >
      {dateCellWrapperProps.children}
    </a>
  )
}

const MyCalendar = (props: any) => {
  const [currentDate, setCurrentDate] = useState(new Date())

  // +5 more events handler
  const handleDrillDown = (date: Date, view: string) => {
    if (view === 'month') {
      return false
    }
    return true
  }

  const myEventsList = [
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 1),
      end: new Date(2024, 0, 1)
    },
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 1),
      end: new Date(2024, 0, 1)
    },
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 1),
      end: new Date(2024, 0, 1)
    },
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 1),
      end: new Date(2024, 0, 1)
    },
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 14),
      end: new Date(2024, 0, 14)
    },
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 14),
      end: new Date(2024, 0, 14)
    },
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 14),
      end: new Date(2024, 0, 14)
    },
    {
      title: '3-3-8 Level 5 Lesson 6..',
      start: new Date(2024, 0, 19),
      end: new Date(2024, 0, 19)
    }
  ]

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1)
    setCurrentDate(prevMonth)
  }

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1)
    setCurrentDate(nextMonth)
  }

  const formatCurrentMonth = (date: any) => {
    return moment(date).format('MMMM YYYY') // Формат: Название месяца Год
  }

  return (
    <>
      <div className='calendar-main'>
        <div className='calendar-head'>
          <div className='calendar-title'>Schedule</div>
          <div className='calendar-head__wrap'>
            <div className='calendar-head__date'>{formatCurrentMonth(currentDate)}</div>
            <div className='calendar-head__today'>Bugun</div>
            <div className='calendar-head__arrows'>
              <div className='arrows'>
                <span className='arrow-left' onClick={handlePrevMonth}>
                  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M14 8L10 12L14 16'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
                <span className='arrow-right' onClick={handleNextMonth}>
                  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M10 16L14 12L10 8'
                      stroke='currentColor'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='calendar-wrap'>
        <div className='myCustomHeight'>
          <BigCalendar
            localizer={localizer}
            events={myEventsList}
            startAccessor='start'
            endAccessor='end'
            popup
            {...props}
            toolbar={false}
            onDrillDown={handleDrillDown}
            dateCellWrapper={dateCellWrapper}
            eventPropGetter={(event: any) => ({
              style: {
                backgroundColor: event.color
              }
            })}
            components={{
              eventWrapper: MyEventWrapper,
              event: MoreLesson,
              dateCellWrapper: dateCellWrapper
            }}
            date={currentDate} // Установите текущую дату для календаря
            onNavigate={(newDate: any) => setCurrentDate(newDate)} // Обновите состояние при навигации
          />
        </div>
      </div>

      <Tooltip id='my-tooltip' place='bottom' clickable={true} openOnClick={true}>
        <EachLesson />
      </Tooltip>
      <Tooltip
        id='date-cell-wrapper'
        place='bottom'
        clickable={true}
        openOnClick={true}
        render={({ content, activeAnchor }) => {
          return <p>{activeAnchor?.getAttribute('data-some-relevant-attr') as string}</p>
        }}
      />
    </>
  )
}

export default MyCalendar

import React from 'react'
import { INotification } from '../../../../types/types'
import moment from 'moment'
interface IProps {
  item: INotification
}
function NotificationCard(props: IProps) {
  return (
    <div>
      <li className={`messages-nots__item ${props.item?.read ? '' : 'new'}`}>
        <div className='messages-nots__wrap'>
          <div className='messages-nots__name'>{props?.item?.message}</div>
          {/* <div className='messages-nots__text'>
            Ever have a project idea you want to document before you forget it? A task you need to assign on the go?
          </div> */}
          <div className='messages-nots__time'>
            <span>{moment(props.item?.created_at).format('YYYY-MM-DD')}</span>
            <span>{moment(props.item?.created_at).format('HH:mm')}</span>
          </div>
        </div>
        {/* <div className='messages-nots__img'>
          <img src={require('../../../../assets/img/mock/notification.jpg')} alt='Notification' />
        </div> */}
      </li>
    </div>
  )
}

export default NotificationCard

import React, { useState } from 'react'

const data = [
  {
    title: '3-3-8 Level 5 Lesson 6..',
    time: '11:30-11:55+05',
    answer:
      ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure nam eligendi assumenda iusto tenetur mollitia quo distinctio doloremque at iste odit aspernatur adipisci, error placeat id quisquam temporibus ullam officiis?',
    typeLesson: 'Individual dars',
    teacher: 'Samatha Blake'
  }
]

const EachLesson = () => {
  const [selected, setSelected] = useState(null)

  return (
    <div className='moreLessonItem'>
      {data.map((item, i) => (
        <>
          <div className='moreLessonItem-title'>
            <div className='lessonName'>{item.title}</div>
            <div className='lessonTime'>{item.time}</div>
          </div>
          <div className='content show'>
            <div className='contentLesson'>
              <div className='contentLesson-title'>Mavzu</div>
              <div className='contentLesson-answer'>{item.title}</div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>Dars turi</div>
              <div className='contentLesson-answer'>{item.typeLesson}</div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>Mavzu</div>
              <div className='contentLesson-answer'>{item.teacher}</div>
            </div>
          </div>
        </>
      ))}
    </div>
  )
}

export default EachLesson

import { useParams } from 'react-router-dom'
import Clock from '../../../assets/img/icons/Clock'
import Coin from '../../../assets/img/icons/Coin'
import Crown from '../../../assets/img/mock/Crown'
import GetContainer from '../../../components/get-container'
import { ISubject } from '../../../types/types'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../configs/request'
import GradeCard from './components/GradeCard'
import CourseCard from '../courses/components/CourseCard'

export const CourseSingle = () => {
  let { slug } = useParams()
  const { i18n, t } = useTranslation()
  return (
    <GetContainer url={'student/subjects/' + slug}>
      {({ data }: { data: { data: ISubject } }) => (
        <>
          <div className='courses-single__head'>
            <div className='courses-single__title'>{data?.data?.name?.[i18n.language]}</div>
            <div className='courses-single__text'>{data?.data?.title?.[i18n.language]}</div>
            <div className='courses-single__img'>
              <img src={MEDIA_URL + data?.data?.banner} alt={data?.data?.slug} />
            </div>
          </div>
          <div className='courses-single__info'>
            <div className='courses-single__title'>{t('About course')}</div>
            <div className='courses-single__wrap'>
              <div
                className='courses-single__content'
                dangerouslySetInnerHTML={{ __html: data?.data?.description?.[i18n.language] }}
              ></div>
              <div className='courses-single__img'>
                <img src={MEDIA_URL + data?.data?.right_media} alt='img' />
              </div>
            </div>
          </div>
          <div className='courses-single__cards'>
            <div className='courses-single__title'>Beginner Course: Curriculum</div>
            <div className='courses-cards'>
              {data?.data?.grades?.map(item => (
                <GradeCard key={item.slug} item={item} />
              ))}
            </div>
            <div className='courses-single__btn'>
              <a href='#' className=' btn'>
                {t('Sign up for the course')}
              </a>
            </div>
          </div>
          <div className='courses-single__more'>
            <div className='courses-single__title'>{t('Suggested courses')}</div>
            <div className='course-list'>
              <GetContainer url='student/subjects'>
                {({ data }) => data?.data?.map((item: ISubject) => <CourseCard key={item.id} item={item} />)}
              </GetContainer>
            </div>
          </div>
        </>
      )}
    </GetContainer>
  )
}

import React, { useState } from 'react'
import GetContainer from '../../../../../components/get-container'
import { ISchedule, ISlot } from '../../../../../types/types'
import { useTranslation } from 'react-i18next'

const data = [
  {
    title: '3-3-8 Level 5 Lesson 6..',
    time: '11:30-11:55+05',
    answer:
      ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure nam eligendi assumenda iusto tenetur mollitia quo distinctio doloremque at iste odit aspernatur adipisci, error placeat id quisquam temporibus ullam officiis?',
    typeLesson: 'Individual dars',
    teacher: 'Samatha Blake'
  }
]

interface IProps {
  id: string
}

const LessonInfo = (props: IProps) => {
  const { i18n, t } = useTranslation()
  return (
    <GetContainer url={'teacher/slots/' + props.id}>
      {({ data }: { data: { data: ISlot } }) => (
        <div className='moreLessonItem'>
          <div className='moreLessonItem-title'>
            <div className='lessonName'>{data?.data?.date}</div>
            <div className='lessonTime'>{data?.data?.time}</div>
          </div>
          <div className='content show'>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson')}: {data?.data?.schedule?.lesson?.name?.[i18n.language]}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Lesson type')}: {data?.data?.schedule?.group?.type}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Students count')}: {data?.data?.schedule?.group?.count}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Status')}: {t(data?.data?.schedule?.main_status)}
              </div>
            </div>
            <div className='contentLesson'>
              <div className='contentLesson-title'>
                {t('Sub status')}: {t(data?.data?.schedule?.sub_status)}
              </div>
            </div>
            {!!data?.data?.schedule?.zoom_link && (
              <div className='contentLesson'>
                <div className='contentLesson-title'>
                  <a href={data?.data?.schedule?.zoom_link}>{t('Zoom link')}</a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </GetContainer>
  )
}

export default LessonInfo
